<template>
	<div class="home">
		<el-progress type="circle" :percentage="percentage"></el-progress>
		<div><el-button type="primary" @click="installFn">安装</el-button></div>
	</div>
</template>
<script>
	export default {
		name: "HomeView",
		data() {
			return {
				percentage: 0,
				timer: null,
			};
		},
		mounted() {
			// 监听pwa是否可加桌   重要
			window.addEventListener("beforeinstallprompt", function (event) {
				window.appPromptEvent = event;
				event.preventDefault();
			});
			window.addEventListener("appinstalled", function () {});
		},
		methods: {
			installFn() {
				var _this = this;

				if (window.appPromptEvent) {
					window.appPromptEvent.stopPropagation();
					window.appPromptEvent.prompt();
					window.appPromptEvent.userChoice.then(async function (result) {
						if (result.outcome === "accepted") {
							console.log("用户同意安装");
							_this.timer = setInterval(() => {
								_this.percentage += 1;
								if (_this.percentage >= 100) {
									clearInterval(_this.timer);
								}
							}, 150);
						} else {
							console.log("用户取消安装");
						}
						window.appPromptEvent = null;
					});
				}
			},
		},
	};
</script>
